import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import axios from 'axios';
import "./Map.css";

import "./App.css";

import ReactGA from 'react-ga';



//mapboxgl.accessToken = 'pk.eyJ1IjoidGJ1ZG5leSIsImEiOiJja3czd2wyZDdhc296Mm5xcDR0eW5mNTd6In0.Cx2XugeiPydpE72bh4jQbw';
mapboxgl.accessToken = 'pk.eyJ1IjoidGJ1ZG5leSIsImEiOiJja3czd2wyZDdhc296Mm5xcDR0eW5mNTd6In0.Cx2XugeiPydpE72bh4jQbw';
//mapboxgl.accessToken = 'pk.eyJ1Ijoibmlja2Zha2VtYW4iLCJhIjoiY2t3NnJ2Nnk3MzJ6NjJ2cGE2d2hwcGgxeSJ9.Hzy2NeYCUXwNFmdv4WWbbw'
const Map = () => {
	//const TRACKING_ID = "UA-XXXXX-X"; // OUR_TRACKING_ID
	//ReactGA.initialize(TRACKING_ID);

	const mapContainerRef = useRef(null);
	let map = useRef(null);

	const mapStateRef = React.createRef();
	const [lng, setLng] = useState(-80.26150498512403);
	const [lat, setLat] = useState(40.49565031767515);
	const [zoom, setZoom] = useState(14.4);
	const bounds = [
		[-80.2830842621038,
			40.474095807569256], // Southwest coordinates
		[-80.24573361637265,
			40.50693752096993] // Northeast coordinates
	];

	const iconImages = [
		{ location: "shuttle.png", name: "shuttleicon" },
		{ location: "3hwt.png", name: "3hwt" },
		{ location: "9fwt.png", name: "9fwt" },
		{ location: "9kwt.png", name: "9kwt" },
		{ location: "11gwt.png", name: "11gwt" },
		{ location: "11swt.png", name: "11swt" },
		{ location: "3hbl.png", name: "3hbl" },
		{ location: "9fbl.png", name: "9fbl" },
		{ location: "9kbl.png", name: "9kbl" },
		{ location: "11gbl.png", name: "11gbl" },
		{ location: "11sbl.png", name: "11sbl" },
		{ location: "3hb.png", name: "3hb" },
		{ location: "9fb.png", name: "9fb" },
		{ location: "9kb.png", name: "9kb" },
		{ location: "11gb.png", name: "11gb" },
		{ location: "11sb.png", name: "11sb" },
		{ location: "3hw.png", name: "3hw" },
		{ location: "9fw.png", name: "9fw" },
		{ location: "9kw.png", name: "9kw" },
		{ location: "11gw.png", name: "11gw" },
		{ location: "11sw.png", name: "11sw" },
		{ location: "3hg.png", name: "3hg" },
		{ location: "9fg.png", name: "9fg" },
		{ location: "9kg.png", name: "9kg" },
		{ location: "11gg.png", name: "11gg" },
		{ location: "11sg.png", name: "11sg" },
	]
	const [currentPos, setCurrentPos] = useState(null);
	const [shuttleETA, setShuttleETA] = useState([
		{
			"shelter": "11S",
			"avgEtaMins": 'Loading',
			"maxEtaMins": 5,

		},
		{
			"shelter": "9K",
			"avgEtaMins": 'Loading',
			"maxEtaMins": 5,
		},
		{
			"shelter": "3H",
			"avgEtaMins": 'Loading',
			"maxEtaMins": 5,
		},
		
		{
			"shelter": "9F",
			"avgEtaMins": 'Loading',
			"maxEtaMins": 5
		}
	])
	let shelterloc = {
		"data": {
			"type": "FeatureCollection",
			"features": [
				{
					"type": "Feature",
					"properties": {
						"icon": "11swt"
					},
					"geometry": {
						"coordinates": [
							-80.26400288060437,
							40.4909779716408
						],
						"type": "Point"
					},
					"id": 0
				},
				{
					"type": "Feature",
					"properties": {
						"icon": "9kwt"
					},
					"geometry": {
						"coordinates": [
							-80.26192514112743,
							40.49075713788642
						],
						"type": "Point"
					},
					"id": 1
				},
				{
					"type": "Feature",
					"properties": {
						"icon": "3hwt"
					},
					"geometry": {
						"coordinates": [
							-80.26107250769735,
							40.49185109740634
						],
						"type": "Point"
					},
					"id": 2
				},
				// {
				// 	"type": "Feature",
				// 	"properties": {
				// 		"icon": "11gwt"
				// 	},
				// 	"geometry": {
				// 		"coordinates": [
				// 			-80.26345781048984,
				// 			40.49242401678157
				// 		],
				// 		"type": "Point"
				// 	},
				// 	"id": 3
				// },
				{
					"type": "Feature",
					"properties": {
						"icon": "9fwt"
					},
					"geometry": {
						"coordinates": [
							-80.26234669461667,
							40.49312483288625
						],
						"type": "Point"
					},
					"id": 4
				}
			]
		},
		"type": "geojson"
	};

	const shuttlePath = {
		"data": {
			"type":"FeatureCollection",
			"features":[
			   {
				  "type":"Feature",
				  "properties":{
					 
				  },
				  "geometry":{
					 "coordinates":[
						[
						   -80.25898592442294,
						   40.495878611920375
						],
						[
						   -80.25879270977883,
						   40.498409417302184
						],
						[
						   -80.25864434734912,
						   40.49881451785805
						],
						[
						   -80.25845542690747,
						   40.49902473277581
						],
						[
						   -80.25804832424431,
						   40.49920930244002
						],
						[
						   -80.25760619439956,
						   40.49936750184989
						],
						[
						   -80.25715593066346,
						   40.49942362829947
						],
						[
						   -80.25658025185622,
						   40.49938619370326
						],
						[
						   -80.25607786445104,
						   40.49914451381147
						],
						[
						   -80.25581195078311,
						   40.49886003267227
						],
						[
						   -80.25558282260614,
						   40.49846296389782
						],
						[
						   -80.25550717341018,
						   40.497959948643654
						],
						[
						   -80.25562825375609,
						   40.494498495759814
						],
						[
						   -80.25530866390872,
						   40.49385983211488
						],
						[
						   -80.25535587836549,
						   40.49352797593886
						],
						[
						   -80.2554776615147,
						   40.493232813138945
						],
						[
						   -80.25591337709116,
						   40.492866133770875
						],
						[
						   -80.25648507087311,
						   40.49265790445915
						],
						[
						   -80.25730512512497,
						   40.492508956248884
						],
						[
						   -80.25791744646952,
						   40.49257981231244
						],
						[
						   -80.25837038933946,
						   40.49275433809353
						],
						[
						   -80.2586940306634,
						   40.49299476605117
						],
						[
						   -80.25899781202227,
						   40.493372405614735
						],
						[
						   -80.25909828102758,
						   40.493801822894085
						],
						[
						   -80.2589088074225,
						   40.496291527821484
						],
						[
						   -80.25892984356561,
						   40.49626634329417
						],
						[
						   -80.25880981422105,
						   40.49831739021795
						],
						[
						   -80.2586662516351,
						   40.49878155099927
						],
						[
						   -80.25849403491125,
						   40.499034963616225
						],
						[
						   -80.25806852966063,
						   40.4992487378812
						],
						[
						   -80.25751764101079,
						   40.49940414123418
						],
						[
						   -80.25690419344343,
						   40.49943016398669
						],
						[
						   -80.25648392811405,
						   40.499360847789404
						],
						[
						   -80.25597054332194,
						   40.49916294201222
						],
						[
						   -80.25556587024712,
						   40.49889094959451
						],
						[
						   -80.25530216834701,
						   40.49866443759339
						],
						[
						   -80.25519049500791,
						   40.49826016643553
						],
						[
						   -80.25521572901104,
						   40.4978724277606
						],
						[
						   -80.25542770672934,
						   40.497555390182214
						],
						[
						   -80.25556048097901,
						   40.497406688870086
						],
						[
						   -80.25570459203331,
						   40.4941294959869
						],
						[
						   -80.25580340391184,
						   40.49347090715128
						],
						[
						   -80.25607287078392,
						   40.49306857584895
						],
						[
						   -80.25644480382216,
						   40.49275122989303
						],
						[
						   -80.25683894740108,
						   40.49262481740203
						],
						[
						   -80.25725063655476,
						   40.49253176297657
						],
						[
						   -80.25790591069456,
						   40.492585650609385
						],
						[
						   -80.25836787974372,
						   40.492793967974904
						],
						[
						   -80.25874193634661,
						   40.493036077136765
						],
						[
						   -80.25903660673922,
						   40.49338815923372
						],
						[
						   -80.25910912784589,
						   40.49377997534438
						],
						[
						   -80.25888652991864,
						   40.49820064726657
						],
						[
						   -80.25888574015697,
						   40.49820395889043
						],
						[
						   -80.25901026669464,
						   40.49850895763416
						],
						[
						   -80.25928436557898,
						   40.498855006180605
						],
						[
						   -80.2597086306084,
						   40.49906715978298
						],
						[
						   -80.26357687111903,
						   40.499412817030105
						],
						[
						   -80.26412966610926,
						   40.49932823718416
						],
						[
						   -80.26471982907793,
						   40.49919731067476
						],
						[
						   -80.26534989158718,
						   40.49890095155399
						],
						[
						   -80.26595422204204,
						   40.498572290047065
						],
						[
						   -80.26641535657602,
						   40.498186151690845
						],
						[
						   -80.26674528246069,
						   40.497859361836476
						],
						[
						   -80.26702813080686,
						   40.49732278111793
						],
						[
						   -80.26721311411563,
						   40.49699212550075
						],
						[
						   -80.26792081707664,
						   40.49430980994637
						],
						[
						   -80.26791762333465,
						   40.49426361291805
						],
						[
						   -80.26769627341257,
						   40.49390237200217
						],
						[
						   -80.26746002433431,
						   40.49348195621826
						],
						[
						   -80.26698126434425,
						   40.49281998763766
						],
						[
						   -80.26652537592855,
						   40.49240007327779
						],
						[
						   -80.26540163041973,
						   40.49148143309412
						],
						[
						   -80.26399222460596,
						   40.49102576153217
						],
						[
						   -80.26399912814156,
						   40.491028860106894
						],
						[
						   -80.2633054989048,
						   40.490919451425555
						],
						[
						   -80.26288478658938,
						   40.490875393085474
						],
						[
						   -80.26236851492084,
						   40.49084502141551
						],
						[
						   -80.26197240555348,
						   40.490828194074425
						],
						[
						   -80.26179612347883,
						   40.4908315569314
						],
						[
						   -80.26140500439564,
						   40.49081811332405
						],
						[
						   -80.2609305500006,
						   40.49082483252141
						],
						[
						   -80.26077665244314,
						   40.49085850682502
						],
						[
						   -80.26025706263238,
						   40.49175997726164
						],
						[
						   -80.2616895865434,
						   40.491782007446886
						],
						[
						   -80.26242958787293,
						   40.4918250143005
						],
						[
						   -80.26313249758279,
						   40.49190082757616
						],
						[
						   -80.26279803371476,
						   40.49312971648828
						],
						[
						   -80.26277754796568,
						   40.49321527406556
						],
						[
						   -80.26268839626546,
						   40.4932026202701
						],
						[
						   -80.26204966629044,
						   40.49317942637492
						],
						[
						   -80.26195050739884,
						   40.49325923162215
						],
						[
						   -80.26192222776857,
						   40.49337028381623
						],
						[
						   -80.26189476877325,
						   40.49434812795886
						],
						[
						   -80.26179678641611,
						   40.49439401857032
						],
						[
						   -80.26141841085004,
						   40.494397853950886
						],
						[
						   -80.25971962183705,
						   40.494359577478605
						],
						[
						   -80.25971879716816,
						   40.494359712204385
						],
						[
						   -80.25952569448933,
						   40.49439073631606
						],
						[
						   -80.25954456133123,
						   40.49462390721314
						],
						[
						   -80.25941412410985,
						   40.49497818151829
						],
						[
						   -80.25923047036207,
						   40.49536778903172
						],
						[
						   -80.25900063601138,
						   40.495916591869815
						],
						[
						   -80.2590124725211,
						   40.49595713681265
						]
					 ],
					 "type":"LineString"
				  }
			   }
			]
		 },
		"type": "geojson"
	};

	//use effect for first draw
	useEffect(() => {
		try {
			const name = window.location.pathname.replaceAll('/', "");
			setCurrentPos(name);
			//console.log(name);
			//mapboxgl.clearStorage();
			//clearCacheData();
			map = new mapboxgl.Map({
				container: mapContainerRef.current,
				//style: "mapbox://styles/nickfakeman/ckw5augcm3k0715msev0ipbgb",
				style: 'mapbox://styles/tbudney/clnaeh1ze07hp01p9f9sfdazm',
				center: [lng, lat],
				zoom: zoom,
				pitch: 0, // pitch in degrees
				//minZoom: 14,
				maxBounds: bounds
			});
			map.on('style.load', function () {
				//console.log('map loaded')
				//setmapstate(true);
				const poslower = window.location.pathname.replaceAll('/', "").toLowerCase();
				shelterloc.data.features.forEach((name) => {
					if (name.properties.icon == poslower + 'wt') {
						//console.log(name.properties.icon)
						name.properties.icon = poslower + 'w'
					}
				});

				map.addSource('shuttlePath', shuttlePath)

				map.addLayer({
					id: 'shuttlePath',
					type: 'line',
					source: 'shuttlePath',
					pitch: 0, // pitch in degrees
					layout: {
						'line-join': 'round',
						'line-cap': 'round'
					},
					paint: {
						//'line-color': '#78BE20',
						'line-color': '#5cb57f',
						'line-width': 2,
						//'line-dasharray': [0, 4, 3]
					}
				});


				map.addSource('shelters2', shelterloc)
				map.addLayer({
					id: "shelters2",
					source: "shelters2",
					type: "symbol",
					pitch: 0, // pitch in degrees
					paint: {},
					layout: {
						"icon-padding": 1,
						"icon-size": 0.18,
						"icon-allow-overlap": true,
						"text-allow-overlap": true,
						"icon-image": ['get', 'icon'],
						//'icon-rotate': ['get', 'bearing'],
						//'text-field': ['get', 'title'],
						'text-font': [
							'Open Sans Semibold',
							'Arial Unicode MS Bold'
						],
						'text-offset': [0, 1.25],
						'text-anchor': 'top',
						"visibility": "visible"
					},
				});


				mapStateRef.current = true;
			})
			//map.removeLayer("shuttles");
			// Add navigation control (the +/- zoom buttons)
			//map.addControl(new mapboxgl.NavigationControl(), "top-right");

			iconImages.forEach((val) => {
				//console.log('loading shuttle icon' + JSON.stringify(val))
				map.loadImage(val.location, (error, image) => {
					if (error) {
						console.error(error)
					}
					else {
						map.addImage(val.name, image);
					}
				});
			})
			//setmapref(map);
			// Clean up on unmount
			fetchData();

		} catch (ex) { }

		return () => map.remove();
	}, []);

	useEffect(() => {
		let repeat;
		//console.log('repeat');

		//console.log(map);
		fetchData();

		try {
			// request again after a minute
			repeat = setInterval(fetchData, 3 * 1000);
		}
		catch (error) {
			console.error(error.message)
		}

		return () => {
			if (repeat) {
				clearInterval(repeat);
			}
		}
	}, []);

	function fetchData() {

		//console.log(mapStateRef.current);
		if (mapStateRef.current == true) {
			const url = 'https://func-samsarashuttle-api.azurewebsites.net/api/ShuttleNOW';
			axios.get(url).then(res => {
				const data = res.data;
				//console.log(res.data)
				if (map.getSource('shelters2') == undefined) {

				}
				if (map.getSource("shuttleSource") !== undefined) {
					//map.removeSource("shuttleSource");
					map.getSource("shuttleSource").setData(data.shuttles.data)
					//console.log(data.shuttles.data);
					//console.log('update shuttle data');
				} else {
					//console.log(data.shuttles);
					map.addSource("shuttleSource", data.shuttles);
					//console.log('setting inital source')
				}

				if (map.getLayer("shuttlesz") !== undefined) {

					//console.log('shuttle layer exists')
				} else {
					//console.log('new shuttle layer');
					map.addLayer({
						id: "shuttlesz",
						pitch: 0, // pitch in degrees
						source: "shuttleSource",
						type: "symbol",
						paint: {},
						layout: {
							"icon-padding": 1,
							"icon-size": 0.6,
							"icon-allow-overlap": true,
							"text-allow-overlap": true,
							"icon-image": "shuttleicon",
							'icon-rotate': ['get', 'bearing'],
							//'text-field': ['get', 'title'],
							'text-font': [
								'Open Sans Semibold',
								'Arial Unicode MS Bold'
							],
							'text-offset': [0, 1.25],
							'text-anchor': 'top',
							"visibility": "visible"
						},
					});
				}
			})
			axios.get('https://func-samsarashuttle-api.azurewebsites.net/api/ShuttleEtaNOW').then(res => {
				const data = res.data;
				if (data != null) { setShuttleETA(data); }
				//console.log(data);

			})

		}

	}

	function etaString(avg, eta) {
		if (avg == 'Loading') { return 'Loading' }
		else if (avg == '0') { return 'Shuttle @ Terminal' }
		else {
			const result = Math.round(avg) + "-" + Math.round(eta) + " mins";
			return result;
		}
	}
	const clearCacheData = () => {
		caches.keys().then((names) => {
			names.forEach((name) => {
				caches.delete(name);
			});
		});
		//console.log('Complete Cache Cleared')
	};
	return (
		<div>
			<div className="box overlay">
				{/* <table>
				<tr>
					<th>Shelter</th>
					<th>Estimated ETA</th>
				</tr>
				<tr>
					<td>11S</td>
					<td>3-8 mins</td>
				</tr>
				<tr>
					<td>3H</td>
					<td>4-9 mins</td>
				</tr>
				<tr>
					<td>9K</td>
					<td>5-10 mins</td>
				</tr>
				<tr>
					<td>11G</td>
					<td>1-2 mins</td>
				</tr>
				<tr className="imhere">
					<td>9F</td>
					<td>2-8 mins</td>
				</tr>
				<tr>
					<td>Terminal</td>
					<td>10-23 mins</td>
				</tr>

			</table> */}

				{/* <table>
				<tr>
					<th>11S</th>
					<th>3H</th>
					<th>9K</th>
					<th>11G</th>
					<th className="highlight">9F</th>
					<th>Terminal</th>

				</tr>
				<tr>
					<td>3-8 m</td>
					<td>4-9 m</td>
					<td>5-10 m</td>
					<td>1-2 m</td>
					<td className="highlight">2-8 m</td>
					<td>10-23 m</td>

				</tr>
			</table> */}
				{/* <table>
				<thead>
					<tr>	
						<th>Shelter</th>
						{shuttleETA.map(shelter=>(<th>{shelter.shelter}</th>))}

					</tr>
				</thead>
				<tbody>
					<tr>
						<td>ETA mins</td>
						{shuttleETA.map(shelter=>(<td>{Math.round(shelter.avgEtaMins) + "-" + Math.round(shelter.maxEtaMins) }</td>))}
					</tr>
				</tbody>
				
			</table> */}

				<table>
					<thead>
						<tr>
							<th>Shelter</th>
							<th>Estimated ETA</th>

						</tr>
					</thead>
					<tbody>


						{shuttleETA.map(shelter => (
							<tr key={shelter.shelter}>
								{currentPos != null &&
									currentPos.trim() == shelter.shelter.trim()
									? <td className="highlight">{shelter.shelter}</td>
									: <td>{shelter.shelter}</td>
								}

								{currentPos == shelter.shelter
									? <td className="highlight">{etaString(shelter.avgEtaMins, shelter.maxEtaMins)}</td>
									: <td>{etaString(shelter.avgEtaMins, shelter.maxEtaMins)}</td>
								}

							</tr>
						))}

					</tbody>

				</table>

			</div>
			<div className="map-container" ref={mapContainerRef} />
		</div>

	);
};

export default Map;
